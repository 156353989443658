import React from "react"
import { Link } from "gatsby"
import Obfuscate from 'react-obfuscate';

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Données personnelles" />
    <section className="hero pattern-zigzag-light" style={{marginTop: -4}}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title dreamland is-size-2">
              Donnees personnelles
            </h1>
          </div>
        </div>
      </section>
      <section>
        <div className="container has-text-justified" style={{padding: "0 1rem"}}>
          <h2 className="is-size-3 almond mt-5 mb-5">Responsable du traitement</h2>
          <p>Le responsable des données traitées par le site <Link to="/">www.agrobioconso.org</Link> est M. Serge Grass, président de l'association « Agro Bio Conso », dont le siège social est situé 77 grande rue 25360 Nancray.</p>
          <h2 className="is-size-3 almond mt-5 mb-5">RGPD</h2>
          <p>L'association « Agro Bio Conso » s’engage à ce que la collecte et le traitement de vos données, effectués à partir du site <Link to="/">www.agrobioconso.org</Link>, soient conformes au RGPD (règlement de l’Union européenne no 2016/679 du 25 mai 2018, dit Règlement Général sur la Protection des Données) et à la Loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés (droit français).</p>
          <h2 className="is-size-3 almond mt-5 mb-5">Collecte de données</h2>
          <p>Lorsque vous naviguez sur le site <Link to="/">www.agrobioconso.org</Link>, vous pouvez être amené(e) à nous contacter par email, à créer un compte utilisateur afin de passer une commande, suivre le statut de cette commande, télécharger vos factures.</p>
          <p>Dans le cadre de ces activités, et sur la base de votre consentement, que vous manifestez en nous contactant par email ou en remplissant le formulaire d’inscription, Agro Bio Conso traite et conserve les données personnelles vous concernant pour répondre à votre demande : les informations fournies sur les formulaires à savoir votre identité, vos coordonnées de contact et, le cas échéant le contenu du message, votre adresse de livraison et de facturation, votre n° de téléphone, ou éventuellement votre date de naissance, ainsi que toute information communiquée ultérieurement lors de nos échanges. Ces données sont traitées par le service concerné par votre demande le temps nécessaire pour vous répondre.</p>
          <p>Les données comptables (bon de commande, de livraison, factures) sont conservées 10 ans à partir de la clôture de l’exercice.</p>
          <p>L’historique des conversations par mail est conservé pendant 6 mois.</p>
          <p>Les informations du compte utilisateur sont conservées pendant 5 ans, renouvelé pour toute nouvelle commande.</p>
          <h2 className="is-size-3 almond mt-5 mb-5">Partage de vos données et sécurité de vos données</h2>
          <p>Les informations collectées ne sont jamais partagées avec d’autres entreprises à des fins de prospection commerciale.</p>
          <p>Agro Bio Conso peut donner accès aux informations collectées à des prestataires informatiques, dans le cadre de leur mission technique de maintenance du site Web. Les prestataires informatiques de Agro Bio Conso assurent via un contrat de prestation de services, leur respect du RGPD ainsi qu’une clause de confidentialité.</p>
          <p>Agro Bio Conso s’engage à protéger vos données personnelles contre toute perte, destruction, l’altération, accès ou divulgation non autorisée. Pour cela, Agro Bio Conso met en œuvre des mesures techniques et organisationnelles appropriées, au regard de la nature des données et des risques que leur traitement comporte, pour préserver la sécurité et la confidentialité de vos données personnelles et, notamment, pour empêcher qu’elles soient déformées, endommagées, ou que des tiers non autorisés y aient accès.</p>
          <h2 className="is-size-3 almond mt-5 mb-5">Vos droits</h2>
          <p>Vous pouvez consulter, modifier, supprimer les données personnelles vous concernant dans la rubrique « mon compte » du site. Il est à noter que nous conserverons les données de commandes pour la durée légale de 10 ans, et qu’un délai de 30 jours pourrait être appliqué pour la gestion d’une demande de modification ou suppression de données.</p>
          <p>Vous pouvez exercer ces droits ou poser toute question relative à la gestion de vos données personnelles par Agro Bio Conso en vous adressant à :</p>
          <p>Par mail à l’adresse suivante : <Obfuscate email="sg44@free.fr" /></p>
          <p>Par courrier à l’adresse suivante : Agro Bio Conso - 77 grande rue 25360 Nancray</p>
          <p>Lorsque vous exercez vos droits, vos données personnelles à des fins de gestion de votre demande (civilité, nom, prénom, nature de la demande, réponse apportée) sont conservées pendant une durée de un an.</p>
          <p>Vous disposez également du droit de saisir la Commission Nationale de l’Informatique et des Libertés (CNIL), 3 Place de Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07.</p>
          <h2 className="is-size-3 almond mt-5 mb-5">Cookies</h2>
          <p>Le site <Link to="/">www.agrobioconso.org</Link> n'utilise pas de cookie de tracking pour suivre les statistiques de visite.</p>
        </div>
      </section>
  </Layout>
)

export default PrivacyPage
